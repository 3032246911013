import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import React, { useEffect, useReducer } from "react";

import useQueryClient from "../../api/query";
import Loading from "../Loading";
import { Hero } from "./Hero";
import { NewCredentialsDialog } from "./NewCredentialsDialog/NewCredentialsDialog";
import { credentialsStoreReducer, initialCredentialsStore } from "./store";
import { CredentialsTable } from "./Table/CredentialsTable";

interface CredentialsDashboardProps {
  practices: PracticeMessage[];
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function CredentialsDashboard({
  practices,
  updateSnackBar,
}: CredentialsDashboardProps) {
  const queryClient = useQueryClient();

  const [credentialsStore, updateCredentialsStore] = useReducer(
    credentialsStoreReducer,
    initialCredentialsStore,
  );

  const credentialsNotLoaded =
    !Array.isArray(credentialsStore.supportedPayers) || practices.length === 0;

  const supportedPayersMap = credentialsStore.supportedPayers
    ? credentialsStore.supportedPayers.reduce(
      (acc, payer) => {
        acc[payer.wieldyId] = payer;
        return acc;
      },
        {} as Record<string, CredentialsSupportedPayersMessage>,
    )
    : {};

  const loadCredentials = async () => {
    try {
      if (credentialsNotLoaded) {
        const [payerMessagePromise, userCredMessagePromise] = await Promise.all(
          [
            queryClient.getCredentialsSupportedPayers(),
            queryClient.getUserCredentials(),
          ],
        );

        updateCredentialsStore({
          type: "ADD_SUPPORTED_PAYERS",
          data: payerMessagePromise || [],
        });
        updateCredentialsStore({
          type: "ADD_USER_CREDENTIALS",
          data: userCredMessagePromise || [],
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch credentials: ${error}`);
    }
  };

  const getUserCredentials = async () => {
    try {
      const userCredMessageResponse = await queryClient.getUserCredentials();
      if (Array.isArray(userCredMessageResponse)) {
        updateCredentialsStore({
          type: "ADD_USER_CREDENTIALS",
          data: userCredMessageResponse,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch user credentials: ${error}`);
    }
  };

  const addOptimisticUserCredential = async ({
    username,
    password,
    payerId,
  }: Partial<UserCredentialsMessage>) => {
    // Generate an ID for the optimistic credential object to allow surfacing it in our dashboard table
    const temporaryClientId = `${payerId}-${username}-${crypto?.randomUUID()}`;
    updateCredentialsStore({
      type: "ADD_USER_CREDENTIAL",
      data: {
        wieldyId: temporaryClientId,
        username,
        password,
        payerId,
      },
    });
  };

  const removeOptimisticUserCredential = async ({
    credentialId,
  }: {
    credentialId: UserCredentialsMessage["wieldyId"];
  }) => {
    updateCredentialsStore({
      type: "REMOVE_USER_CREDENTIAL",
      data: credentialId,
    });
  };

  const updateOptimisticUserCredential = async (
    credentials: Partial<UserCredentialsMessage> & {
      wieldyId: UserCredentialsMessage["wieldyId"];
    },
  ) => {
    updateCredentialsStore({
      type: "UPDATE_USER_CREDENTIAL",
      data: credentials,
    });
  };

  const unmaskUserCredential = async (
    wieldyId: string,
    attributeName: string,
  ) => {
    const userCredMessageResponse = await queryClient.unmaskAttribute(
      wieldyId,
      attributeName,
    );
    updateCredentialsStore({
      type: "UPDATE_USER_CREDENTIAL",
      data: { wieldyId, ...userCredMessageResponse },
    });
    return userCredMessageResponse[
      attributeName as keyof Partial<UserCredentialsMessage>
    ];
  };

  useEffect(() => {
    loadCredentials();
  }, []);

  if (credentialsNotLoaded) {
    return (
      <Box>
        <Hero
          callToAction={
            <Button disabled variant="contained">
              Add Credential
            </Button>
          }
        />
        <Fade in timeout={500}>
          <Box sx={{ marginTop: "10rem" }}>
            <Loading />
          </Box>
        </Fade>
      </Box>
    );
  }
  return (
    <Box>
      <Hero
        callToAction={
          <NewCredentialsDialog
            credentialsStore={credentialsStore}
            practices={practices}
            afterFormSubmit={async ({
              payerId,
              practiceId,
              username,
              password,
            }) => {
              await addOptimisticUserCredential({
                payerId,
                practiceId,
                username,
                password,
              });
              await getUserCredentials();
            }}
            updateSnackBar={updateSnackBar}
          />
        }
      />
      <CredentialsTable
        updateSnackBar={updateSnackBar}
        supportedPayersMap={supportedPayersMap}
        practiceNames={practices.map(({ displayName }) => displayName)}
        credentialNames={
          Array.from(
            new Set(
              credentialsStore.userCredentials?.map(
                ({ payerName, name }) => name || payerName || "-",
              ),
            ),
          ).sort() || []
        }
        generateRow={() => {
          const row = credentialsStore.userCredentials
            ?.map(
              ({
                wieldyId,
                practiceId,
                payerId,
                payerName,
                username,
                password,
                notes,
                updatedAt,
                name,
                status,
                website,
              }) => ({
                id: wieldyId,
                wieldyId,
                payerId,
                practiceId,
                payerName,
                username,
                password,
                notes,
                updatedAt,
                practices,
                name,
                status,
                website,
                removeUserCredential: () => {
                  if (wieldyId) {
                    removeOptimisticUserCredential({
                      credentialId: wieldyId,
                    });
                  }
                },
                updateUserCredential: (
                  credentials: Partial<UserCredentialsMessage>,
                ) => {
                  if (wieldyId) {
                    updateOptimisticUserCredential({
                      wieldyId,
                      ...credentials,
                    });
                  }
                },
                unmaskValue: async (attributeName: string) => {
                  // We're only unmasking via back-end for supported payer
                  // credentials
                  if (
                    wieldyId &&
                    payerName &&
                    (attributeName as keyof UnmaskAttributes) !== undefined
                  ) {
                    return unmaskUserCredential(wieldyId, attributeName);
                  }
                  return "**********";
                },
                getUserCredentials,
              }),
            )
            .sort((a, b) => {
              const aDate = a.updatedAt ? new Date(a.updatedAt) : new Date();
              const bDate = b.updatedAt ? new Date(b.updatedAt) : new Date();
              return bDate.getTime() - aDate.getTime();
            });
          return row || [];
        }}
      />
    </Box>
  );
}
