export enum BankMatchState {
  UNMATCHED,
  MATCHED,
}

export enum BankTransactionType {
  INSURANCE,
  UNKNOWN,
  OTHER,
}
