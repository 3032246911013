import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";
import React, { Dispatch, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { isDevMode } from "../../utils/utils";
import {
  PaymentPlanStore,
  PaymentPlanStoreAction,
} from "../PaymentPlans/store";
import { PostedState } from "../posting";
import {
  initialPatientStore,
  PatientsStore,
  PatientsStoreAction,
  patientsStoreReducer,
} from "./store";
import PatientProfileView from "./views/PatientProfileView";
import PatientsListView from "./views/PatientsListView";
import NewPatientModal from "./views/PatientsListView/NewPatientModal";

interface PatientsDashboardProps {
  store: PatientsStore;
  paymentPlanStore: PaymentPlanStore;
  updateStore: Dispatch<PatientsStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  practices: PracticeMessage[];
  updatePaymentPlanStore: Dispatch<PaymentPlanStoreAction>;
}

export { initialPatientStore, patientsStoreReducer };

export default function PatientsDashboard({
  store,
  paymentPlanStore,
  updateStore,
  updatePaymentPlanStore,
  queryClient,
  updateSnackBar,
  practices,
}: PatientsDashboardProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [triggerReload, setTriggerReload] = useState(false);
  const devMode = isDevMode();

  /**
   * Immediate redirection are jarring and prevent a user from
   * receiving success or failure feedback.
   */
  useEffect(() => {
    const delayInMilliseconds = 750;
    let timeoutId: ReturnType<typeof window.setTimeout>;

    if (triggerReload) {
      timeoutId = setTimeout(() => {
        const currentPage = 0;
        navigate(currentPage);
      }, delayInMilliseconds);
    }

    return () => clearTimeout(timeoutId);
  }, [triggerReload]);

  const createPatient = async (
    patient: PatientMessage,
    paymentMethodToken?: string,
  ) => {
    try {
      const { wieldyId, ...createPatientMessage } = patient;
      const wieldyPatientId =
        await queryClient.postPatient(createPatientMessage);
      if (paymentMethodToken) {
        await queryClient.postPatientPayment(
          wieldyPatientId,
          paymentMethodToken,
        );
      }
      updateSnackBar({
        severity: "success",
        message: "Successfully created patient",
      });
      const redirect = searchParams.get("redirect");
      if (redirect) {
        searchParams.delete("redirect");
        const to = { pathname: redirect, search: searchParams.toString() };
        navigate(to);
        return;
      }
      navigate({
        pathname: `/patients/${wieldyPatientId}`,
        search: searchParams.toString(),
      });
    } catch (_error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to create patient. Please try again.",
      });
    }
  };

  const editPatient = async (patient: PatientMessage) => {
    try {
      await queryClient.updatePatient(patient);
      updateSnackBar({
        severity: "success",
        message: "Successfully edited patient",
      });
      const redirect = searchParams.get("redirect");
      if (redirect) {
        searchParams.delete("redirect");
        const to = { pathname: redirect, search: searchParams.toString() };
        navigate(to);
        return;
      }

      setTriggerReload(true);
    } catch (_error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to edit patient. Please try again later",
      });
    }
  };

  const loadPatients = async () => {
    const patients = await queryClient.getPatients();
    updateStore({
      type: "SET_PATIENTS",
      patients,
    });
  };

  const resetPatients = () => {
    updateStore({
      type: "RESET_PATIENTS",
    });
  };

  const loadPatientAndClaims = async (patientId: string) => {
    const patientClaims = await queryClient.getPatientClaims(patientId);
    updateStore({ type: "SET_PATIENT", patient: patientClaims });
    const claimIds = (patientClaims?.Claim ?? []).map((c) => c.wieldyId);
    const claims = claimIds.length
      ? await queryClient.getClaimsProceduresAndPatient(claimIds)
      : [];
    updateStore({
      type: "SET_CLAIMS",
      claims,
    });
  };

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    try {
      updateStore({
        type: "UPDATE_CLAIM",
        claim: { ...claim, postedState: PostedState[PostedState.POSTING] },
      });
      updateSnackBar({
        severity: "info",
        message: "Posting to PMS Patient Ledger",
      });
      const postResponse = await queryClient.postToPMS(claim.wieldyId);
      updateStore({
        type: "UPDATE_CLAIM",
        claim: {
          ...claim,
          postedState: postResponse.postedState,
          postedDateTime: postResponse.postedDateTime,
          postedAttempts: postResponse.postedAttempts,
          postedLatestErrorMessage: postResponse.postedLatestErrorMessage,
        },
      });
      updateSnackBar({
        severity: "success",
        message: "Successfully posted to PMS",
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.data) {
        const responseBody = error.response.data;
        updateSnackBar({
          severity: "error",
          message: responseBody.postedLatestErrorMessage,
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claim: {
            ...claim,
            postedState: responseBody.postedState,
            postedAttempts: responseBody.postedAttempts,
            postedLatestErrorMessage: responseBody.postedLatestErrorMessage,
          },
        });
      }
    }
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claim: { ...claim, ...claimWork },
    });
    await queryClient.postClaim(claimWork);
  };

  const resetPatientAndClaims = () => {
    updateStore({
      type: "RESET_PATIENT",
    });
    updateStore({
      type: "RESET_CLAIMS",
    });
  };

  const loadPatientPaymentPlans = async (
    patientId: PatientMessage["wieldyId"],
  ) => {
    if (devMode && patientId) {
      try {
        const payments = await queryClient.getPatientPaymentPlans(patientId);
        if (payments) {
          updatePaymentPlanStore({
            type: "SET_LOADED_PAYMENT_PLANS",
            payload: payments,
          });
        }
      } catch (_error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to load patient payment methods",
        });
      }
    }
  };

  const clearPatientPaymentPlan = async (
    paymentPlanId: PaymentPlanMessage["id"],
  ) => {
    if (devMode && paymentPlanId) {
      try {
        await queryClient.cancelPatientPaymentPlans(paymentPlanId);

        updatePaymentPlanStore({
          type: "CLEAR_PAYMENT_PLAN",
          payload: paymentPlanId,
        });
      } catch (_error) {
        updateSnackBar({
          severity: "error",
          message: "Failed to cancel patient payment plan",
        });
      }
    }
  };

  const loadPatientPayments = async (patientId: PatientMessage["wieldyId"]) => {
    if (devMode && patientId) {
      try {
        const payments = await queryClient.getPatientPayments(patientId);
        updatePaymentPlanStore({
          type: "SET_CURRENT_PAYMENTS_OPTIONS",
          payload: payments ?? [],
        });

        if (payments && payments.length) {
          const primaryPaymentMethod = payments.find(
            (payment) => payment.primary,
          );
          if (primaryPaymentMethod) {
            updatePaymentPlanStore({
              type: "SET_PAYMENT_METHOD",
              payload: primaryPaymentMethod,
            });
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        updateSnackBar({
          severity: "error",
          message: "Failed to load patient payment methods",
        });
      }
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PatientsListView
            patients={store.patients}
            practices={practices}
            patientsLoading={!store.loadedPatients}
            load={loadPatients}
            reset={resetPatients}
          />
        }
      />
      <Route
        path="/:patientId"
        element={
          <PatientProfileView
            patient={store.patient}
            patientLoading={!store.loadedPatient}
            claims={store.claims}
            claimsLoading={!store.loadedClaims}
            load={loadPatientAndClaims}
            loadPatientPaymentPlans={loadPatientPaymentPlans}
            paymentPlans={paymentPlanStore.loadedPaymentPlans}
            paymentPlanStore={paymentPlanStore}
            loadPatientPayments={loadPatientPayments}
            update={updateClaim}
            editPatient={editPatient}
            reset={resetPatientAndClaims}
            toPMS={postToPMS}
            updateSnackBar={updateSnackBar}
            practices={practices}
            clearPatientPaymentPlan={clearPatientPaymentPlan}
          />
        }
      />
      <Route
        path="/new-patient"
        element={
          <NewPatientModal
            updateSnackBar={updateSnackBar}
            onSubmit={createPatient}
            practices={practices}
            onClose={() => {
              navigate(-1);
            }}
            open
          />
        }
      />
    </Routes>
  );
}
