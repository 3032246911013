import { zodResolver } from "@hookform/resolvers/zod";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useForm, useWatch } from "react-hook-form";

import useQueryClient from "../../../api/query";
import { stall } from "../../../utils/utils";
import { CREDENTIAL_TYPE } from "../common";
import { CredentialsStore } from "../store";
import { AddPayerCredentialFormFields } from "./AddPayerCredentialFormFields";
import {
  AddPayerCredentialsFormFields,
  addPayerCredentialsFormSchemaBase,
} from "./PayerCredentialsForm.zod";

interface AddPayerCredentialsProps {
  payers: CredentialsStore["supportedPayers"];
  practices: PracticeMessage[];
  userCredentials: CredentialsStore["userCredentials"];
  cancel: () => void;
  afterFormSubmit?: ({
    payerId,
    practiceId,
    username,
    password,
  }: Partial<UserCredentialsMessage>) => Promise<void>;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
}

export function AddPayerCredentials({
  payers,
  practices,
  userCredentials,
  cancel,
  afterFormSubmit,
  updateSnackBar,
}: AddPayerCredentialsProps) {
  const defaultPracticeId = practices.length === 1 ? practices[0].wieldyId : "";

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<AddPayerCredentialsFormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    delayError: 1000,
    resolver: zodResolver(addPayerCredentialsFormSchemaBase),
    defaultValues: {
      practiceId: defaultPracticeId,
    },
  });

  const selectedPractice = useWatch({
    control,
    name: "practiceId",
  });

  const selectedType = useWatch({
    control,
    name: "type",
  });

  const selectedProvider = useWatch({
    control,
    name: "payer",
  });

  const queryClient = useQueryClient();

  const onSubmit = async ({
    type,
    payer,
    unsupportedPayer,
    practiceId,
    username,
    password,
    website,
    notes,
  }: AddPayerCredentialsFormFields) => {
    try {
      if (type === CREDENTIAL_TYPE.PAYER) {
        if (payer && !unsupportedPayer) {
          const body = {
            type,
            practiceId,
            payerId: payer,
            username,
            password,
            website,
            notes,
          };
          await Promise.all([
            await queryClient.postCreateCredentials(body),
            await stall(),
          ]);
        } else if (unsupportedPayer) {
          const body = {
            type: "PAYER",
            practiceId,
            name: unsupportedPayer,
            username,
            password,
            website,
            notes,
          };
          await Promise.all([
            await queryClient.postOtherCredentials(body),
            await stall(),
          ]);
        }
      }

      if (type === CREDENTIAL_TYPE.PMS) {
        // TODO: post to create pms credentials
      }

      if (afterFormSubmit) {
        await afterFormSubmit({
          practiceId,
          payerId: payer,
          username,
          password,
        });
      }
      updateSnackBar({
        severity: "success",
        message: "Successfully saved credentials.",
      });
    } catch (error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to save credentials. Try again.",
      });
      // eslint-disable-next-line no-console
      console.error(`Failed to fetch credentials: ${error}`);
    }
  };

  const hasErrors = Object.keys(errors).length > 0;
  const isDisabled = isSubmitting || hasErrors || !isValid;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(
        async ({
          type,
          payer,
          unsupportedPayer,
          practiceId,
          username,
          website,
          notes,
          password,
        }) => {
          await onSubmit({
            type,
            payer,
            unsupportedPayer,
            practiceId,
            username,
            website,
            password,
            notes,
          });
        },
      )}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AddPayerCredentialFormFields
        control={control}
        payers={payers}
        practices={practices}
        userCredentials={userCredentials}
        selectedPractice={selectedPractice}
        selectedType={selectedType}
        selectedPayer={selectedProvider}
        cancel={cancel}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "auto",
          gap: "12px",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#EAECF0",
            color: "#475467",
            borderRadius: "8px",
            "&:hover": {
              backgroundColor: "#e6e7ea",
            },
          }}
          onClick={() => cancel()}
          type="button"
          variant="contained"
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          sx={{ borderRadius: "8px" }}
          type="submit"
          color="primary"
          variant="contained"
          disabled={isDisabled}
          data-testid="submitCredentials"
        >
          {isSubmitting ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <CircularProgress size={20} />
              Submitting...
            </Box>
          ) : (
            "Continue"
          )}
        </Button>
      </Box>
    </Box>
  );
}

AddPayerCredentials.defaultProps = {
  afterFormSubmit: undefined,
};
