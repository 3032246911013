/* eslint-disable react/no-unused-prop-types */
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import Box from "@mui/material/Box";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import AutoSizer from "react-virtualized-auto-sizer";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url,
).toString();

interface InvoiceProps {
  pdf?: Blob;
}

export default function Invoice({ pdf }: InvoiceProps) {
  return (
    <Box
      width="50%"
      sx={{
        backgroundColor: "#E6E6EA",
        padding: "16px",
      }}
    >
      {pdf && (
        <AutoSizer disableHeight>
          {({ width }: { width: number }) => (
            <Box marginX="auto" width={width} sx={{ position: "relative" }}>
              <Document file={pdf}>
                <Page width={width} pageNumber={1} />
              </Document>
            </Box>
          )}
        </AutoSizer>
      )}
    </Box>
  );
}

Invoice.defaultProps = {
  pdf: undefined,
};
