import { replaceByWieldyId } from "../../utils/utils";

export type ClaimsStore = {
  claims: ClaimWithProcedureAndPatientMessage[];
  loadedClaims: boolean;
};

export const initialClaimsStore: ClaimsStore = {
  claims: [],
  loadedClaims: false,
};

export type ClaimsStoreAction =
  | { type: "RESET_CLAIMS" }
  | { type: "SET_CLAIMS"; claims: ClaimWithProcedureAndPatientMessage[] }
  | {
      type: "UPDATE_CLAIM";
      claimToUpdate: ClaimWithProcedureAndPatientMessage;
    };

export function claimsStoreReducer(
  store: ClaimsStore,
  action: ClaimsStoreAction,
): ClaimsStore {
  switch (action.type) {
    case "SET_CLAIMS":
      return {
        ...store,
        claims: action.claims,
        loadedClaims: true,
      };
    case "RESET_CLAIMS":
      return {
        ...store,
        claims: [],
        loadedClaims: false,
      };
    case "UPDATE_CLAIM":
      return {
        ...store,
        claims: replaceByWieldyId(store.claims, action.claimToUpdate) ?? [
          action.claimToUpdate,
        ],
      };
    default:
      return store;
  }
}
