import { Tooltip, Zoom } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import React from "react";

import { formatMoney } from "../../formatters";
import { isoToDate } from "../../utils/utils";
import Loading from "../Loading";
import { renderCellRedacted } from "../redact";
import CustomNoRowsOverlay from "../TableNoDataOverlay";

const columns: GridColDef[] = [
  {
    flex: 0.7,
    field: "procedureCode",
    headerName: "Code",
  },
  {
    flex: 0.7,
    field: "procedurePayerSubstitutedCode",
    headerName: "Substituted Code",
  },
  {
    flex: 0.7,
    field: "procedureToothNumber",
    headerName: "Tooth Number",
  },
  {
    flex: 1,
    type: "date",
    field: "procedureDate",
    headerName: "Date of Service",
    valueGetter: (params: GridRenderCellParams) =>
      params.row.procedureDate ? isoToDate(params.row.procedureDate) : "",
    renderCell: renderCellRedacted,
  },
  {
    flex: 0.7,
    type: "number",
    field: "procedureSubmittedAmount",
    headerName: "Submitted Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    type: "number",
    field: "procedureContractedAmount",
    headerName: "Contracted Amount",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    field: "procedureDenialCode",
    headerName: "Denial Code",
    renderCell: (params: GridRenderCellParams) =>
      params.row.procedureDenialDescription ? (
        // This a temporary fix till we have denial code description
        // return an object. The backend delimits each code description with a pipe
        // and we replace that pipe with a new line (\n\n) here for the tooltip.
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {params.row.procedureDenialDescription.replaceAll(" | ", "\n\n")}
            </div>
          }
          TransitionComponent={Zoom}
          placement="top"
          arrow
        >
          <div>{params.value}</div>
        </Tooltip>
      ) : (
        params.value
      ),
  },
  {
    flex: 1,
    type: "number",
    field: "procedurePatientResponsibility",
    headerName: "Patient Pays",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
  {
    flex: 1,
    type: "number",
    field: "procedurePayerPays",
    headerName: "Payer Pays",
    valueFormatter: (params: GridValueFormatterParams<number>) =>
      params.value === null ? "-" : formatMoney(params.value),
    renderCell: renderCellRedacted,
  },
];

interface ProceduresTableProps {
  procedures: ProcedureMessage[];
  isLoading: boolean;
}

export default function ProceduresTable({
  procedures,
  isLoading,
}: ProceduresTableProps) {
  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <DataGridPro
      rowHeight={33}
      getRowSpacing={getRowSpacing}
      sx={{
        border: 0,
        fontSize: "0.8rem",
        weight: "500",
        lineHeight: "20px",
        color: "#475467",
        [`&>.${gridClasses.main}`]: {
          [`&>.${gridClasses.columnHeaders}`]: {
            borderBottom: "none",
            backgroundColor: "#F9FAFB",
            fontSize: "0.9rem",
            color: "#303030",
          },
        },
      }}
      rows={procedures.map((procedure, key) => ({ ...procedure, key }))}
      columns={columns}
      getRowId={(row) => JSON.stringify(row)}
      disableRowSelectionOnClick
      disableVirtualization
      initialState={{
        sorting: {
          sortModel: [{ field: "procedureDate", sort: "desc" }],
        },
      }}
      slots={{
        noRowsOverlay: CustomNoRowsOverlay,
      }}
    />
  );
}
