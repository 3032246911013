export type CredentialsStore = {
  supportedPayers?: CredentialsSupportedPayersMessage[];
  userCredentials?: (
    | UserCredentialsMessage
    | Partial<UserCredentialsMessage>
  )[];
};

export const initialCredentialsStore: CredentialsStore = {
  supportedPayers: undefined,
  userCredentials: undefined,
};

export type CredentialsStoreAction =
  | {
      type: "ADD_SUPPORTED_PAYERS";
      data: CredentialsSupportedPayersMessage[];
    }
  | {
      type: "ADD_USER_CREDENTIALS";
      data: UserCredentialsMessage[];
    }
  | {
      type: "ADD_USER_CREDENTIAL";
      data: Partial<UserCredentialsMessage>;
    }
  | {
      type: "REMOVE_USER_CREDENTIAL";
      data: UserCredentialsMessage["wieldyId"];
    }
  | {
      type: "UPDATE_USER_CREDENTIAL";
      data: Partial<UserCredentialsMessage> & {
        wieldyId: UserCredentialsMessage["wieldyId"];
      };
    };

export function credentialsStoreReducer(
  store: CredentialsStore,
  action: CredentialsStoreAction,
): CredentialsStore {
  switch (action.type) {
    case "ADD_SUPPORTED_PAYERS":
      return {
        ...store,
        supportedPayers: action.data,
      };
    case "ADD_USER_CREDENTIALS":
      return {
        ...store,
        userCredentials: action.data,
      };
    case "ADD_USER_CREDENTIAL":
      return {
        ...store,
        userCredentials: store.userCredentials
          ? [...store.userCredentials, action.data]
          : [action.data],
      };
    case "REMOVE_USER_CREDENTIAL":
      return {
        ...store,
        userCredentials: store.userCredentials?.filter(
          ({
            wieldyId,
          }: UserCredentialsMessage | Partial<UserCredentialsMessage>) =>
            wieldyId !== action.data,
        ),
      };
    case "UPDATE_USER_CREDENTIAL":
      return {
        ...store,
        userCredentials: store.userCredentials?.map((credential) => {
          if (action.data.wieldyId === credential.wieldyId) {
            return { ...credential, ...action.data };
          }

          return credential;
        }),
      };
    default:
      return store;
  }
}
