import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";
import React, { Dispatch, useState } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { PostedState } from "../posting";
import ClaimsListView from "./ClaimsListView";
import { ClaimsStore, ClaimsStoreAction } from "./store";

interface ClaimsDashboardProps {
  store: ClaimsStore;
  updateStore: Dispatch<ClaimsStoreAction>;
  queryClient: QueryClient;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  loadPractices: () => Promise<void>;
  selectedPractices: PracticeMessage[];
}

export default function ClaimsDashboard({
  store,
  updateStore,
  queryClient,
  updateSnackBar,
  loadPractices,
  selectedPractices,
}: ClaimsDashboardProps) {
  const [isBatchPosting, updateIsBatchPosting] = useState<boolean>(false);
  const loadClaims = async () => {
    await loadPractices();
    updateStore({
      claims: await queryClient.getClaimsProceduresAndPatient([]),
      type: "SET_CLAIMS",
    });
  };

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    try {
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: PostedState[PostedState.POSTING],
        },
      });
      updateSnackBar({
        severity: "info",
        message: "Posting to PMS",
      });
      const postResponse = await queryClient.postToPMS(claim.wieldyId);
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: postResponse.postedState,
          postedDateTime: postResponse.postedDateTime,
          postedAttempts: postResponse.postedAttempts,
        },
      });
      updateSnackBar({
        severity: "success",
        message: "Successfully posted to PMS",
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.data) {
        const responseBody = error.response.data;

        updateSnackBar({
          severity: "error",
          message: responseBody.postedLatestErrorMessage,
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: responseBody.postedState,
            postedAttempts: responseBody.postedAttempts,
            postedLatestErrorMessage: responseBody.postedLatestErrorMessage,
          },
        });
      }
    }
  };

  const resetClaims = () => {
    updateStore({
      type: "RESET_CLAIMS",
    });
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: { ...claim, ...claimWork },
    });
    await queryClient.postClaim(claimWork);
  };

  const batchPostPMS = async () => {
    try {
      updateIsBatchPosting(true);
      updateSnackBar({
        severity: "info",
        message: "Started batching posting to PMS",
      });
      await queryClient.batchPostToPMS();
      updateSnackBar({
        severity: "success",
        message: "Successfully started batch posting to PMS",
      });
      updateIsBatchPosting(false);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        updateSnackBar({
          severity: "error",
          message: "Failed to start batch posting to PMS",
        });
      }
      updateIsBatchPosting(false);
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClaimsListView
            load={loadClaims}
            reset={resetClaims}
            claims={store.claims}
            loading={!store.loadedClaims}
            batchPostPMS={batchPostPMS}
            isBatchPosting={isBatchPosting}
            updateClaim={updateClaim}
            postToPMS={postToPMS}
            selectedPractices={selectedPractices}
          />
        }
      />
    </Routes>
  );
}
